import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { MenuProps, components } from 'react-select'
import styled from 'styled-components'

const MENU_ANIMATIONS = {
	closed: {
		opacity: 0,
		y: -16,
	},
	open: {
		opacity: 1,
		y: 0,
		transition: {
			opacity: {
				duration: 0.2,
			},
		},
	},
}

const Menu: React.FC<MenuProps> = ({ children, ...rest }) => {
	const isOpen = rest.selectProps.menuIsOpen
	return (
		<AnimatePresence>
			{isOpen && (
				<MenuWrapper
					animate={'open'}
					initial={'closed'}
					exit={'closed'}
					variants={MENU_ANIMATIONS}
					id='menu'
				>
					<components.Menu {...rest}>{children}</components.Menu>
				</MenuWrapper>
			)}
		</AnimatePresence>
	)
}

export default Menu

const MenuWrapper = styled(motion.div)``
