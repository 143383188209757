import { motion } from 'framer-motion'
import React from 'react'
import { DropdownIndicatorProps, components } from 'react-select'
import styled from 'styled-components'

const ANIMATIONS = {
	open: {
		rotate: 180,
	},
	closed: {
		rotate: 0,
	},
}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = ({ isDisabled, isFocused, ...rest }) => {
	const isOpen = rest.selectProps.menuIsOpen
	const fill = isDisabled ? 'var(--grey)' : 'var(--deep-blue)'
	return (
		<components.DropdownIndicator
			isFocused={isFocused}
			isDisabled={isDisabled}
			{...rest}
		>
			<DropdownWrapper>
				<motion.svg
					width={19}
					height={11}
					viewBox='0 0 19 11'
					xmlns='http://www.w3.org/2000/svg'
					variants={ANIMATIONS}
					animate={isOpen ? 'open' : 'closed'}
					fill={fill}
				>
					<path d='M7.94414 10.5594C8.53007 11.1453 9.48164 11.1453 10.0676 10.5594L17.5676 3.05935C18.1535 2.47341 18.1535 1.52185 17.5676 0.935913C16.9816 0.349976 16.0301 0.349976 15.4441 0.935913L9.00351 7.37654L2.56289 0.9406C1.97695 0.354663 1.02539 0.354663 0.439453 0.9406C-0.146484 1.52654 -0.146484 2.4781 0.439453 3.06404L7.93945 10.564L7.94414 10.5594Z' />
				</motion.svg>
			</DropdownWrapper>
		</components.DropdownIndicator>
	)
}

export default DropdownIndicator

const DropdownWrapper = styled.div`
	padding-right: var(--spacer-2xs);
`
