import React from 'react'
import ReactSelect, { GetOptionValue, Props as SelectProps } from 'react-select'

import DropdownIndicator from './DropdownIndicator'
import Menu from './Menu'

type Option = {
	label: string
	value: string | number
}

type LabelProps = { label?: undefined; inputId?: string } | { label: React.ReactNode; inputId: string }

interface Props extends Omit<SelectProps, 'getOptionValue' | 'getOptionLabel' | 'styles' | 'components'> {
	// width?: SelectWidth | string
	value?: Option | Option[]
	options?: Array<Option>
	valueKey?: string
	labelKey?: string
	onChange?: (val) => void
	label?: React.ReactNode
	clearText?: React.ReactNode
}

function Select({
	// width,
	value,
	options = [],
	valueKey = 'value',
	labelKey = 'label',
	label,
	clearText = 'Hreinsa',
	onChange,
	...rest
}: Props & LabelProps) {
	return (
		<ReactSelect
			styles={getStyles()}
			value={value}
			options={options}
			getOptionValue={(option: any) => option[valueKey]}
			getOptionLabel={(option: any) => option[labelKey]}
			hideSelectedOptions={false}
			//@ts-ignore
			label={label}
			clearText={clearText}
			components={{ DropdownIndicator, Menu }}
			onChange={(newValue, actionMeta) => onChange && onChange(newValue)}
			{...rest}
		/>
	)
}

export default Select

const getStyles = () => ({
	option: (provided, state) => ({
		...provided,
		color: 'var(--input-text-color)',
		borderRadius: 24,
		backgroundColor: 'transparent',
		padding: '12px 16px 12px 24px',
		fontSize: '1.25rem',
		lineHeight: '2rem',
		cursor: 'pointer',
		...(state.isFocused && { backgroundColor: 'var(--blue-300)' }), // lighter on hover
		...(state.isSelected && { backgroundColor: 'var(--blue-150)' }), // darker on select
	}),
	singleValue: (provided) => {
		return { ...provided, color: 'var(--input-text-color)' }
	},
	noOptionsMessage: (provided) => {
		return { ...provided, color: 'var(--grey)' }
	},
	placeholder: (provided) => {
		return { ...provided, color: 'var(--grey)' }
	},
	multiValue: (provided) => {
		return { ...provided, backgroundColor: 'var(--blue-100)' }
	},
	menu: (provided) => {
		return {
			...provided,
			borderRadius: '24px',
			marginTop: '4px',
			boxShadow:
				'0px 100px 80px rgba(0, 0, 0, 0.07), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888)',
		}
	},
	menuList: (provided) => {
		return { ...provided }
	},
	control: (provided, state) => {
		return {
			...provided,
			borderRadius: '56px',
			height: '56px',
			// border: 0,
			paddingLeft: 'var(--spacer-xs)',
			fontSize: '1.25rem',
			transition: 'border 0.1s',
			border: '2px solid transparent',
			'&:hover': {
				border: '2px solid var(--blue-300) !important',
				...(state.isFocused === false && { border: '2px solid var(--blue-300)' }),
			},
			...(state.isFocused && {
				border: '2px solid var(--blue-500)',
				color: 'grey',
			}),
			...(state.isDisabled && {
				cursor: 'wait',
			}),
			...(!state.isDisabled && {
				cursor: 'pointer',
			}),
		}
	},
	container: (provided) => {
		return { ...provided, fontSize: '16px' }
	},
	indicatorSeparator: () => {
		return {}
	},
	multiValueRemove: (provided) => {
		return {
			...provided,
			position: 'relative',
			backgroundColor: 'var(--blue-300)',
			color: 'var(--input-text-color)',
			'&:hover': {
				backgroundColor: 'var(--blue-150)',
			},
		}
	},
})
